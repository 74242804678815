/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";
import ReactImageUploading from "react-images-uploading";
import ReactDatePicker from "react-datepicker";
import { Link, useParams } from "react-router-dom";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* REACT ICONS */
import { FiTrash } from "react-icons/fi";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* USER DEFINED COMPONENTS */
import InputField from "../../../components/input";
import { getOrganizationDetailsById } from "../../../service/api";

const ViewOrganization = () => {
  const { id } = useParams();
  // STATE VARIABLES
  const [loading, setLoading] = useState(false);
  const [organizationDetails, setOrganizationDetails] = useState(null);
  const [category, setCategory] = useState();
  const [organizationImages, setOrganizationImages] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  /* CHOOSE IMAGES */
  const onImageChange = (imageList) => {
    setOrganizationImages(imageList);
  };

  /* HANDLE DELETE DATA */
  const handleGetOrganizationDetails = () => {
    getOrganizationDetailsById(id)
      .then((res) => {
        toast.success(res?.message);
        setOrganizationDetails(res?.data);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetOrganizationDetails();
  }, [id]);

  return (
    <section className="px-0 py-2 xl:p-5 lg:p-5 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>View Organization | Lohana Mahaparishad</title>
      </Helmet>

      <div className="container-full-width">
        {/* HEADING */}
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-ibm-bold text-black tracking-tight">
              View Organization
            </h1>
            <h2 className="text-base font-ibm-regular text-greyText">
              Find the details of this organization here
            </h2>
          </div>
          <div className="flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
            <Link to="/organization">
              <Button className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-5 px-5 py-3 rounded-md border border-authBorder text-sm font-ibm-semibold text-black normal-case transition-all duration-300 ease-in-out">
                Back to organizations' list
              </Button>
            </Link>
          </div>
        </div>

        {/* FORM */}
        <div className="mt-5">
          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-y-5 xl:gap-5 lg:gap-5">
            {/* DETAILS */}
            <div className="col-span-2">
              <div className="bg-white rounded-lg border border-authBorder">
                <div className="px-5 py-3 border-b border-authBorder">
                  <h5 className="text-lg font-ibm-bold text-black">
                    Organization Details
                  </h5>
                  <p className="text-sm font-ibm-regular text-greyText">
                    View these organization specific details
                  </p>
                </div>
                <div className="p-5">
                  <form>
                    <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-ibm-regular text-black">
                            Organization Name{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <InputField
                              type="text"
                              name="organizationName"
                              id="organizationName"
                              disabled={true}
                              value={organizationDetails?.organizationName}
                              placeholder="Write the organization name"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-ibm-regular text-black">
                            Establishment Date{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <InputField
                              type="text"
                              name="stock"
                              id="stock"
                              disabled={true}
                              value={organizationDetails?.establishmentDate}
                              placeholder="Write the stock in inventory"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-ibm-regular text-black">
                            Email Address{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <InputField
                              type="text"
                              name="organizationSKU"
                              id="organizationSKU"
                              disabled={true}
                              value={organizationDetails?.email}
                              placeholder="Write the organization sku"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-ibm-regular text-black">
                            Phone Number <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <InputField
                              type="text"
                              name="organizationPrice"
                              id="organizationPrice"
                              disabled={true}
                              value={organizationDetails?.phoneNumber}
                              placeholder="Write the organization price"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-span-2">
                        <div className="form-group">
                          <label className="text-sm font-ibm-regular text-black">
                            Organization Description{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <Editor
                              toolbar={{ options: ["inline", "link"] }}
                              editorClassName="text-editor"
                              wrapperClassName="text-editor-wrapper"
                              placeholder="Write the organization description here"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="bg-white rounded-lg border border-authBorder mt-5">
                <div className="px-5 py-3 border-b border-authBorder">
                  <h5 className="text-lg font-ibm-bold text-black">
                    Organization Address
                  </h5>
                  <p className="text-sm font-ibm-regular text-greyText">
                    View these organization addresses
                  </p>
                </div>
                {organizationDetails?.address?.length === 0 && (
                  <>
                    <div className="p-5">
                      <p className="text-base font-ibm-regular text-greyText">
                        No Addresses found
                      </p>
                    </div>
                  </>
                )}

                {organizationDetails?.address?.length !== 0 &&
                  organizationDetails?.address?.map((address, index) => {
                    return (
                      <div className={index !== 0 ? "p-5 border-t-2" : "p-5"}>
                        <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                          <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                            <div className="form-group">
                              <label className="text-sm font-ibm-semibold text-black">
                                Organization Name{" "}
                                <span className="text-red-500">*</span>
                              </label>
                              <div className="mt-1">
                                <InputField
                                  type="text"
                                  name="organizationName"
                                  id="organizationName"
                                  disabled={true}
                                  value={organizationDetails?.organizationName}
                                  placeholder="Write the organization name"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                            <div className="form-group">
                              <label className="text-sm font-ibm-semibold text-black">
                                Establishment Date{" "}
                                <span className="text-red-500">*</span>
                              </label>
                              <div className="mt-1">
                                <InputField
                                  type="text"
                                  name="stock"
                                  id="stock"
                                  disabled={true}
                                  value={organizationDetails?.establishmentDate}
                                  placeholder="Write the stock in inventory"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                            <div className="form-group">
                              <label className="text-sm font-ibm-semibold text-black">
                                Email Address{" "}
                                <span className="text-red-500">*</span>
                              </label>
                              <div className="mt-1">
                                <InputField
                                  type="text"
                                  name="organizationSKU"
                                  id="organizationSKU"
                                  disabled={true}
                                  value={organizationDetails?.email}
                                  placeholder="Write the organization sku"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                            <div className="form-group">
                              <label className="text-sm font-ibm-semibold text-black">
                                Phone Number{" "}
                                <span className="text-red-500">*</span>
                              </label>
                              <div className="mt-1">
                                <InputField
                                  type="text"
                                  name="organizationPrice"
                                  id="organizationPrice"
                                  disabled={true}
                                  value={organizationDetails?.phoneNumber}
                                  placeholder="Write the organization price"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-span-2">
                            <div className="form-group">
                              <label className="text-sm font-ibm-semibold text-black">
                                Organization Description{" "}
                                <span className="text-red-500">*</span>
                              </label>
                              <div className="mt-1">
                                <Editor
                                  toolbar={{ options: ["inline", "link"] }}
                                  editorClassName="text-editor"
                                  wrapperClassName="text-editor-wrapper"
                                  placeholder="Write the organization description here"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            {/* IMAGES  */}
            <div className="col-span-1">
              {/* DETAILS */}
              <div className="bg-white rounded-lg border border-authBorder">
                <div className="px-5 py-3 border-b border-authBorder">
                  <h5 className="text-lg font-ibm-bold text-black">
                    Organization Insights
                  </h5>
                  <p className="text-sm font-ibm-regular text-greyText">
                    View organization type and website URL
                  </p>
                </div>
                <div className="p-5">
                  <div className="form-group">
                    <label className="text-sm font-ibm-semibold text-black">
                      Organization Type <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <Select
                        options={[
                          { label: "Mahajan", value: "Mahajan" },
                          {
                            label: "Charitable Trust",
                            value: "Charitable Trust",
                          },
                          {
                            label: "Community Group",
                            value: "Community Group",
                          },
                          { label: "Hostel", value: "Hostel" },
                        ]}
                        classNamePrefix="dropdown"
                        placeholder="Select category"
                        name="organizationCategory"
                        id="organizationCategory"
                        value={{
                          label: organizationDetails?.type,
                          value: organizationDetails?.type,
                        }}
                        isDisabled={true}
                      />
                    </div>
                  </div>
                  <div className="mt-5 grid grid-cols-2 gap-5">
                    <div className="col-span-2 ">
                      <div className="form-group">
                        <label className="text-sm font-ibm-semibold text-black">
                          Website URL <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-1">
                          <InputField
                            type="text"
                            name="organizationPrice"
                            id="organizationPrice"
                            disabled={true}
                            value={organizationDetails?.websiteUrl}
                            placeholder="Write the organization price"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* IMAGES */}
              <div className="bg-white rounded-lg border border-authBorder mt-5">
                <div className="px-5 py-3 border-b border-authBorder">
                  <h5 className="text-lg font-ibm-bold text-black">
                    Organization Images
                  </h5>
                  <p className="text-sm font-ibm-regular text-greyText">
                    Attach the Logo images for this organization
                  </p>
                </div>
                <div className="p-5">
                  <div className="grid grid-cols-2 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                    {organizationDetails?.organizationImage ? (
                      <div className="col-span-1 relative">
                        <img
                          src={organizationDetails?.organizationImage}
                          className="w-full h-organizationImage object-cover rounded-md"
                          alt="Organization"
                        />
                      </div>
                    ) : (
                      <div className="col-span-1">
                        <div className="w-full h-organizationImage rounded-md bg-[#F5F5F5] bg-opacity-50 border border-authBorder cursor-pointer hover:border-logoYellow transition-all duration-300 ease-in-out p-5">
                          {/* <AiFillPlusCircle className="w-8 h-8 text-greyText mx-auto" /> */}
                          <div className="mt-2">
                            <p className="text-s font-ibm-semibold text-greyText text-center">
                              No Images found
                            </p>
                            {/* <p className="text-xs font-ibm-regular text-greyText text-center mt-1">
                                (JPG, JPEG, PNG)
                              </p> */}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ViewOrganization;
