/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

import { Helmet } from "react-helmet";

import moment from "moment";
import { Link } from "react-router-dom";

import DataTable from "react-data-table-component";
import Input from "../../../components/input";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Dialog, DialogBody, Tooltip } from "@material-tailwind/react";

/* ICONS */
import { IoEyeSharp, IoClose } from "react-icons/io5";
import { BiEdit, BiExport } from "react-icons/bi";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* API IMPORT */
import {
  deleteMedicalAidDetails,
  getMedicalAidList,
} from "../../../service/api";
import { SET_FORMAT } from "config";

const MedicalAid = () => {
  // STATE VARIABLES
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);

  const [medicalAidList, setMedicalAidList] = useState([]);

  const [totalDocs, setTotalDocs] = useState([]);

  const [search, setSearch] = useState("");

  const [deleteId, setDeleteId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  /* FUNCTIONS */
  const handlePageChange = async (e) => {
    setPage(e);
    getMedicalAidListFunction();
  };

  const handlePerPageChange = async (e) => {
    setSizePerPage(e);
    getMedicalAidListFunction();
  };

  const getMedicalAidListFunction = () => {
    setLoading(true);
    const params = {
      page: page,
      sizePerPage: sizePerPage,
    };
    if (search) {
      params.search = search;
    }

    getMedicalAidList(params)
      .then((res) => {
        setMedicalAidList(res?.data?.docs);
        setTotalDocs(res?.data?.totalDocs);
      })
      .catch((e) => {
        console.log("e-->", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /* HANDLE DELETE DATA */
  const handleDelete = () => {
    deleteMedicalAidDetails(deleteId)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setDeleteModal(!deleteModal);
        setDeleteId(null);
        getMedicalAidListFunction();
      });
  };

  /* COLUMNS */
  const columns = [
    {
      name: "Sr. No",
      grow: 0.5,
      selector: (row, index) => (
        <span className="text-sm font-ibm-regular text-greyText">
          {index + 1}
        </span>
      ),
    },
    {
      name: "Patient Details",
      grow: 1.5,
      selector: (row) => (
        <div className="p-0">
          <p className="text-sm font-ibm-semibold text-black">
            {row?.patientDetails?.firstName} {row?.patientDetails?.lastName}
          </p>
          <a
            href={`tel:${row?.patientDetails?.phoneNumber}`}
            className="mt-1 text-sm font-ibm-regular text-blue-400 block underline hover:text-red-400 transition-all duration-300 ease-in-out"
          >
            {row?.patientDetails?.phoneNumber}
          </a>
        </div>
      ),
    },
    {
      name: "Zone  / District",
      grow: 2,
      selector: (row) => (
        <span className="text-sm font-ibm-regular text-greyText">
          {row?.zoneId?.shortDescription} / {row?.district}
        </span>
      ),
    },
    {
      name: "Hospital/Clinic Details",
      grow: 2,
      selector: (row) => (
        <>
          <p className="text-sm font-ibm-regular text-black">
            {row?.medicalDetails?.doctorName} - (
            {row?.medicalDetails?.hospitalName})
          </p>
          <p className="mt-1 text-sm font-ibm-regular text-greyText">
            {row?.medicalDetails?.hospitalAddress}
          </p>
        </>
      ),
    },
    {
      name: "Cost of Treatment",
      selector: (row) => (
        <span className="text-sm font-ibm-semibold text-black">
          {SET_FORMAT(row?.medicalDetails?.costOfTreatment)}
        </span>
      ),
    },
    {
      name: "Timestamp",
      grow: 1,
      selector: (row) => (
        <span className="text-sm font-ibm-regular text-greyText">
          {moment(row.createdAt).format("MMM DD, YYYY ")}
        </span>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="flex items-center">
          <Tooltip
            content="View form details"
            className="text-sm font-ibm-semibold"
          >
            <Link
              target="_blank"
              to={"/medical-aid/view-medical-aid/" + row?._id}
            >
              <Button className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5">
                <IoEyeSharp className="w-5 h-5 text-greyText" />
              </Button>
            </Link>
          </Tooltip>

          <Tooltip
            content="Export in form"
            className="text-sm font-ibm-semibold"
          >
            <Link
              target="_blank"
              to={"/medical-aid/export-medical-aid/" + row?._id}
            >
              <Button className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5">
                <BiExport className="w-5 h-5 text-greyText" />
              </Button>
            </Link>
          </Tooltip>

          <Tooltip
            content="Edit form details"
            className="text-sm font-ibm-semibold"
          >
            <Link
              target="_blank"
              to={"/medical-aid/edit-medical-aid/" + row?._id}
            >
              <Button className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5">
                <BiEdit className="w-5 h-5 text-greyText" />
              </Button>
            </Link>
          </Tooltip>
        </div>
      ),
    },
  ];

  /* USE-EFFECTS */
  useEffect(() => {
    getMedicalAidListFunction();
  }, [page, sizePerPage, search]);

  /* LOADING SCREEN FOR DATATABLE */
  const LoadingScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-ibm-semibold text-black text-center">
          Please wait!
        </p>
        <p className="text-sm font-ibm-semibold text-black text-center">
          We are fetching data{" "}
        </p>
      </div>
    );
  };

  const NoDataScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-ibm-semibold text-black text-center">
          There are no queries to display.
        </p>
      </div>
    );
  };

  return (
    <section className="px-0 py-2 xl:p-5 lg:p-5 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Medical Aid Queries | Lohana Mahaparishad</title>
      </Helmet>

      <div className="container-full-width">
        {/* HEADING */}
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-ibm-bold text-black tracking-tight">
              Medical Aid
            </h1>
            <h2 className="text-base font-ibm-regular text-greyText">
              Manage the queries by your users from website.
            </h2>
          </div>
          <div className="block xl:flex lg:flex items-center gap-2">
            {/* SEARCHBAR */}
            <div className="w-3/4 xl:w-full lg:w-full mt-2 xl:mt-0 lg:mt-0">
              <Input
                type="text"
                placeholder="Search..."
                name="search"
                id="search"
                value={search}
                onChange={(e) => setSearch(e?.target?.value)}
              />
            </div>
          </div>
        </div>

        <div className="mt-5">
          <DataTable
            className="data-table"
            columns={columns}
            data={totalDocs > 0 ? medicalAidList : []}
            progressPending={loading}
            progressComponent={<LoadingScreen />}
            pagination
            paginationServer
            paginationTotalRows={totalDocs}
            onChangeRowsPerPage={handlePerPageChange}
            onChangePage={handlePageChange}
            noDataComponent={<NoDataScreen />}
          />
        </div>

        <Dialog
          open={deleteModal}
          handler={() => setDeleteModal(!deleteModal)}
          size="xs"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogBody className="p-0">
            <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
              <h5 className="text-lg font-ibm-bold text-black tracking-tight">
                Delete Category
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setDeleteModal(!deleteModal)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>
            <div className="p-5 border-b border-authBorder">
              <p className="text-base font-ibm-regular text-black">
                Are you sure you want to delete this category?
              </p>
            </div>
            <div className="flex items-center justify-end px-5 py-3">
              <Button
                onClick={handleDelete}
                className="text-sm shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md font-ibm-semibold text-white transition-all duration-300 ease-in-out normal-case"
              >
                Delete details
              </Button>
            </div>
          </DialogBody>
        </Dialog>
      </div>
    </section>
  );
};

export default MedicalAid;
