/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

/* COMPONENTS */
import InputField from "../../../components/input";
import TextareaField from "../../../components/textarea";

/* ICONS */
import { IoDocumentText } from "react-icons/io5";
import { FaInfoCircle } from "react-icons/fa";
import { FaFilePdf } from "react-icons/fa6";

/* PACKAGES */
import Select from "react-select";
import PhoneInput from "react-phone-number-input";
import ReactImageUploading from "react-images-uploading";
import ReactDatePicker from "react-datepicker";
import DataTable from "react-data-table-component";

import { useFormik } from "formik";
import * as yup from "yup";

/* DATA */
import { DISTRICT_DATA } from "../../../data/district-list";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Tooltip } from "@material-tailwind/react";

/* VALIDATIONS */
import {
  editMedicalAidDetailsById,
  getMedicalAidDetailsById,
} from "../../../service/api";
import { toast } from "react-toast";
import { Link, useParams } from "react-router-dom";

/* FILE SIZE CONVERSION */
const bytesToSize = (bytes) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "n/a";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]})`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

const medicalFormValidation = yup.object().shape({
  zoneId: yup.object().required("Zone is Required"),
  district: yup.object().required("District is Required"),
  patientFirstName: yup.string().required("First Name is Required"),
  patientMiddleName: yup.string().required(),
  patientLastName: yup.string().required(),
  nativePlace: yup.string().required(),
  patientAge: yup.string().required(),
  phoneNumber: yup.string().required(),
  addressLineOne: yup.string().required(),
  addressPinCode: yup.string().required(),
  annualIncomeAmount: yup.string().required(),
  earningMemberCount: yup.string().required(),
  medicalDetails: yup.object().shape({
    medicalCondition: yup.string().required(),
    sufferingSince: yup.string(),
    doctorName: yup.string().required(),
    hospitalName: yup.string().required(),
    admittedAt: yup.string(),
    hospitalAddress: yup.string().required(),
    costOfTreatment: yup.string().required(),
    careTakerName: yup.string().required(),
    careTakerPhoneNumber: yup.string().required(),
    healthInsuranceName: yup.string().required(),
    healthInsuranceAmountAvailed: yup.string(),
    additionalFundsAvailed: yup.string().required(),
    additionalFundsAmountAvailed: yup.string(),
    previousMedicalAidFromLMP: yup.string().required(),
    previousMedicalAidFromLMPAmountAvailed: yup.string(),
  }),
  bankDetails: yup.object().shape({
    accountHolder: yup.string().required(),
    bankName: yup.string().required(),
    branch: yup.string().required(),
    accountNumber: yup.string().required(),
    ifscCode: yup.string().required(),
    date: yup.date().required(),
    signature: yup.string().required(),
  }),
  familyDetails: yup.array().required(),
  incomeProof: yup.mixed().required(),
  costEstimate: yup.mixed().required(),
  photo: yup.mixed().required(),
  reports: yup.mixed().required(),
});

export default function MedicalAidForm() {
  const { id } = useParams();

  /* STATE VARIABLE */
  const [loading, setLoading] = useState(false);
  const [medicalAidFormDetails, setMedicalAidFormDetails] = useState(null);

  /* LOHANA MAHAPARISHAD STATES */
  const [regionsList, setRegionsList] = useState([]);

  const [familyDetails, setFamilyDetails] = useState([
    {
      no: 1,
      familyMember: "",
      age: "",
      occupation: "",
      monthlyIncome: "",
      relation: "",
    },
    {
      no: 2,
      familyMember: "",
      age: "",
      occupation: "",
      monthlyIncome: "",
      relation: "",
    },
    {
      no: 3,
      familyMember: "",
      age: "",
      occupation: "",
      monthlyIncome: "",
      relation: "",
    },
    {
      no: 4,
      familyMember: "",
      age: "",
      occupation: "",
      monthlyIncome: "",
      relation: "",
    },
    {
      no: 5,
      familyMember: "",
      age: "",
      occupation: "",
      monthlyIncome: "",
      relation: "",
    },
    {
      no: 6,
      familyMember: "",
      age: "",
      occupation: "",
      monthlyIncome: "",
      relation: "",
    },
    {
      no: 7,
      familyMember: "",
      age: "",
      occupation: "",
      monthlyIncome: "",
      relation: "",
    },
  ]);

  /* HANDLE FAMILY DETAILS CHANGE */
  const handleFamilyDetailsChange = (id, fieldName, value) => {
    let currentVersion = familyDetails?.find((element) => element?.no === id);
    let filteredVersion = familyDetails?.filter(
      (element) => element?.no !== id
    );

    currentVersion[fieldName] = value;
    filteredVersion.push(currentVersion);

    setFamilyDetails(
      filteredVersion?.sort(function(a, b) {
        return new Date(a?.no) - new Date(b?.no);
      })
    );
  };

  /* TABLE COLUMNS */
  const columns = [
    {
      name: "No.",
      grow: 0.5,
      selector: (row) => (
        <p className="text-sm font-ibm-regular text-greyText">{row?.no}</p>
      ),
    },
    {
      name: "Family member's name",
      grow: 2,
      selector: (row) => (
        <p className="text-sm font-ibm-semibold text-black text-wrap leading-relaxed">
          {row?.familyMember === "" ? "-" : row?.familyMember}
        </p>
      ),
    },
    {
      name: "Age",
      selector: (row) => (
        <p className="text-sm font-ibm-regular text-black text-wrap leading-relaxed">
          {row?.age === "" ? "-" : row?.age}
        </p>
      ),
    },
    {
      name: "Occupation",
      selector: (row) => (
        <p className="text-sm font-ibm-regular text-black text-wrap leading-relaxed">
          {row?.occupation === "" ? "-" : row?.occupation}
        </p>
      ),
    },
    {
      name: "Monthly Income",
      selector: (row) => (
        <p className="text-sm font-ibm-regular text-black text-wrap leading-relaxed">
          {row?.monthlyIncome === "" ? "-" : row?.monthlyIncome}
        </p>
      ),
    },
    {
      name: "Relation",
      selector: (row) => (
        <p className="text-sm font-ibm-regular text-black">
          {row?.relation === "" ? "-" : row?.relation}
        </p>
      ),
    },
  ];

  /* STEP ONE FORM HANDLING */
  const formikForm = useFormik({
    initialValues: {
      zoneId: "",
      district: "",
      patientFirstName: "",
      patientMiddleName: "",
      patientLastName: "",
      nativePlace: "",
      patientAge: "",
      phoneNumber: "",
      addressLineOne: "",
      addressPinCode: "",
      annualIncomeAmount: "",
      earningMemberCount: "",
      medicalDetails: {
        medicalCondition: "",
        sufferingSince: "",
        doctorName: "",
        hospitalName: "",
        admittedAt: "",
        hospitalAddress: "",
        costOfTreatment: "",
        careTakerName: "",
        careTakerPhoneNumber: "",
        healthInsuranceName: "",
        healthInsuranceAmountAvailed: "",
        additionalFundsAvailed: "",
        additionalFundsAmountAvailed: "",
        previousMedicalAidFromLMP: "",
        previousMedicalAidFromLMPAmountAvailed: "",
      },
      bankDetails: {
        accountHolder: "",
        bankName: "",
        branch: "",
        accountNumber: "",
        ifscCode: "",
        date: new Date(),
      },
      familyDetails: "",
      incomeProof: "",
      costEstimate: "",
      photo: "",
      reports: "",
      stamp: "",
      mahajanPresident: "",
      zonalPresident: "",
      signatureOffice: "",
      mahajanDetails: {
        nearestMahajan: "",
        name: "",
        age: "",
        livingIn: "",
        illness: "",
      },
      zonalRegionOfficeDetails: {
        designation: "",
        name: "",
        address: "",
        designationInMahaparishad: "",
      },
      officeDetails: {
        interviewDate: "",
        interviewName: "",
        grantedAssistance: "",
        chequeDetailsName: "",
        chequeDetailsAmount: "",
        chequeDetailsNumber: "",
        chequeDetailsDate: "",
      },
      filesList: "",
    },
    validationSchema: medicalFormValidation,
    onSubmit: (values) => {
      const obj = {
        district: values?.district?.value,
        zoneId: values?.zoneId?.value,
        patientFirstName: values?.patientFirstName,
        patientMiddleName: values?.patientMiddleName,
        patientLastName: values?.patientLastName,
        nativePlace: values?.nativePlace,
        patientAge: String(values?.patientAge),
        phoneNumber: values?.phoneNumber,
        addressLineOne: values?.addressLineOne,
        addressPinCode: String(values?.addressPinCode),
        annualIncomeAmount: String(values?.annualIncomeAmount),
        earningMemberCount: String(values?.earningMemberCount),
        medicalDetails: {
          medicalCondition: values?.medicalDetails?.medicalCondition,

          doctorName: values?.medicalDetails?.doctorName,
          hospitalName: values?.medicalDetails?.hospitalName,

          hospitalAddress: values?.medicalDetails?.hospitalAddress,
          costOfTreatment: String(values?.medicalDetails?.costOfTreatment),
          careTakerName: values?.medicalDetails?.careTakerName,
          careTakerPhoneNumber: values?.medicalDetails?.careTakerPhoneNumber,
          healthInsuranceName: values?.medicalDetails?.healthInsuranceName,
          additionalFundsAvailed:
            values?.medicalDetails?.additionalFundsAvailed,
          previousMedicalAidFromLMP:
            values?.medicalDetails?.previousMedicalAidFromLMP,
          previousMedicalAidFromLMPAmountAvailed: String(
            values?.medicalDetails?.previousMedicalAidFromLMPAmountAvailed
          ),
        },
        bankDetails: {
          accountHolder: values?.bankDetails?.accountHolder,
          bankName: values?.bankDetails?.bankName,
          branch: values?.bankDetails?.branch,
          accountNumber: String(values?.bankDetails?.accountNumber),
          ifscCode: values?.bankDetails?.ifscCode,
          date: values?.bankDetails?.date,
          signature: values?.bankDetails?.signature,
        },
        familyDetails: values?.familyDetails,
      };

      if (values?.medicalDetails?.sufferingSince) {
        obj.medicalDetails.sufferingSince =
          values?.medicalDetails?.sufferingSince;
      }

      if (values?.medicalDetails?.admittedAt) {
        obj.medicalDetails.admittedAt = values?.medicalDetails?.admittedAt;
      }

      if (values?.medicalDetails?.healthInsuranceAmountAvailed) {
        obj.medicalDetails.healthInsuranceAmountAvailed = String(
          values?.medicalDetails?.healthInsuranceAmountAvailed
        );
      }
      if (values?.medicalDetails?.additionalFundsAmountAvailed) {
        obj.medicalDetails.additionalFundsAmountAvailed = String(
          values?.medicalDetails?.additionalFundsAmountAvailed
        );
      }
      if (values?.medicalDetails?.previousMedicalAidFromLMPAmountAvailed) {
        obj.medicalDetails.previousMedicalAidFromLMPAmountAvailed = String(
          values?.medicalDetails?.previousMedicalAidFromLMPAmountAvailed
        );
      }

      const formData = new FormData();

      formData.append("data", JSON.stringify(obj));
      formData.append("photo", formikForm.values.photo?.[0]?.file);
      formData.append("costEstimate", formikForm.values.costEstimate);
      formData.append("incomeProof", formikForm.values.incomeProof);
      formData.append("reports", formikForm.values.reports);

      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }
      setLoading(true);
      editMedicalAidDetailsById(formData)
        .then((res) => {
          if (res?.status) {
            toast(
              "Your details have been captured. We will get back to you shortly."
            );
            formikForm.handleReset();
            formikForm.setFieldValue("phoneNumber", "");

            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            toast.error(res?.message);
          }
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handleGetMedicalFormDetails = () => {
    getMedicalAidDetailsById(id)
      .then((res) => {
        toast.success(res?.message);
        setMedicalAidFormDetails(res?.data);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      });
  };

  /* USE EFFECT */
  useEffect(() => {
    let tempArray = [];
    DISTRICT_DATA?.forEach((element) => {
      tempArray.push({
        label: element?.District,
        value:
          element?.District + "(" + element?.["State / Union Territory"] + ")",
        zoneId: element?.zoneId,
        zoneName: element?.Zone,
      });
    });
    setRegionsList(tempArray);
  }, []);

  /* USE EFFECT */
  useEffect(() => {
    if (familyDetails) {
      formikForm.setFieldValue("familyDetails", familyDetails);
    }
  }, [familyDetails]);

  /* USE EFFECT */
  useEffect(() => {
    if (medicalAidFormDetails) {
      formikForm.setFieldValue(
        "district",
        regionsList?.find((el) => el?.zoneId === medicalAidFormDetails?.zoneId)
      );
      formikForm.setFieldValue("zoneId", {
        label: regionsList?.find(
          (el) => el?.zoneId === medicalAidFormDetails?.zoneId
        )?.zoneName,
        value: regionsList?.find(
          (el) => el?.zoneId === medicalAidFormDetails?.zoneId
        )?.zoneId,
      });
      formikForm.setFieldValue(
        "patientFirstName",
        medicalAidFormDetails?.patientDetails?.firstName
      );
      formikForm.setFieldValue(
        "patientMiddleName",
        medicalAidFormDetails?.patientDetails?.middleName
      );
      formikForm.setFieldValue(
        "patientLastName",
        medicalAidFormDetails?.patientDetails?.lastName
      );
      formikForm.setFieldValue(
        "nativePlace",
        medicalAidFormDetails?.patientDetails?.nativePlace
      );
      formikForm.setFieldValue(
        "patientAge",
        medicalAidFormDetails?.patientDetails?.age
      );
      formikForm.setFieldValue(
        "phoneNumber",
        medicalAidFormDetails?.patientDetails?.phoneNumber
      );
      formikForm.setFieldValue(
        "addressLineOne",
        medicalAidFormDetails?.patientDetails?.address?.addressLine1
      );
      formikForm.setFieldValue(
        "addressPinCode",
        medicalAidFormDetails?.patientDetails?.address?.pinCode
      );
      formikForm.setFieldValue(
        "annualIncomeAmount",
        medicalAidFormDetails?.patientDetails?.annualIncome?.amount
      );
      formikForm.setFieldValue(
        "earningMemberCount",
        medicalAidFormDetails?.patientDetails?.earningMemberCount
      );

      formikForm.setFieldValue(
        "medicalDetails.medicalCondition",
        medicalAidFormDetails?.medicalDetails?.medicalCondition
      );
      formikForm.setFieldValue(
        "medicalDetails.hospitalName",
        medicalAidFormDetails?.medicalDetails?.hospitalName
      );
      formikForm.setFieldValue(
        "medicalDetails.hospitalAddress",
        medicalAidFormDetails?.medicalDetails?.hospitalAddress
      );
      formikForm.setFieldValue(
        "medicalDetails.careTakerName",
        medicalAidFormDetails?.medicalDetails?.careTakerName
      );
      formikForm.setFieldValue(
        "medicalDetails.careTakerPhoneNumber",
        medicalAidFormDetails?.medicalDetails?.careTakerPhoneNumber
      );

      formikForm.setFieldValue(
        "medicalDetails.additionalFundsAvailed",
        medicalAidFormDetails?.medicalDetails?.additionalFundsAvailed?.name
      );
      formikForm.setFieldValue(
        "medicalDetails.additionalFundsAmountAvailed",
        medicalAidFormDetails?.medicalDetails?.additionalFundsAvailed?.amount
      );

      formikForm.setFieldValue(
        "medicalDetails.costOfTreatment",
        medicalAidFormDetails?.medicalDetails?.costOfTreatment
      );

      formikForm.setFieldValue(
        "medicalDetails.doctorName",
        medicalAidFormDetails?.medicalDetails?.doctorName
      );
      formikForm.setFieldValue(
        "medicalDetails.healthInsuranceName",
        medicalAidFormDetails?.medicalDetails?.healthInsuranceName?.name
      );
      formikForm.setFieldValue(
        "medicalDetails.healthInsuranceAmountAvailed",
        medicalAidFormDetails?.medicalDetails?.healthInsuranceName?.amount
      );
      formikForm.setFieldValue(
        "medicalDetails.previousMedicalAidFromLMP",
        medicalAidFormDetails?.medicalDetails?.previousMedicalAidFromLMP?.name
      );
      formikForm.setFieldValue(
        "medicalDetails.previousMedicalAidFromLMPAmountAvailed",
        medicalAidFormDetails?.medicalDetails?.previousMedicalAidFromLMP?.amount
      );
      formikForm.setFieldValue(
        "medicalDetails.sufferingSince",
        medicalAidFormDetails?.medicalDetails?.sufferingSince
      );
      formikForm.setFieldValue(
        "medicalDetails.admittedAt",
        medicalAidFormDetails?.medicalDetails?.admittedAt
      );

      formikForm.setFieldValue(
        "familyDetails",
        medicalAidFormDetails?.familyDetails
      );
      setFamilyDetails(medicalAidFormDetails?.familyDetails);

      formikForm.setFieldValue(
        "bankDetails.accountHolder",
        medicalAidFormDetails?.bankDetails?.accountHolder
      );
      formikForm.setFieldValue(
        "bankDetails.bankName",
        medicalAidFormDetails?.bankDetails?.bankName
      );
      formikForm.setFieldValue(
        "bankDetails.branch",
        medicalAidFormDetails?.bankDetails?.branch
      );
      formikForm.setFieldValue(
        "bankDetails.accountNumber",
        medicalAidFormDetails?.bankDetails?.accountNumber
      );
      formikForm.setFieldValue(
        "bankDetails.ifscCode",
        medicalAidFormDetails?.bankDetails?.ifscCode
      );
      formikForm.setFieldValue(
        "bankDetails.date",
        medicalAidFormDetails?.bankDetails?.date
      );
      formikForm.setFieldValue(
        "bankDetails.signature",
        medicalAidFormDetails?.bankDetails?.signature?.url
      );
    }
  }, [medicalAidFormDetails]);

  /* USE EFFECT */
  useEffect(() => {
    handleGetMedicalFormDetails();
  }, [id]);

  return (
    <section className="px-0 py-2 xl:p-5 lg:p-5 min-h-screen xl:min-h-full lg:min-h-full">
      {/* HEADING */}
      <div className="block xl:flex lg:flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-ibm-bold text-black tracking-tight">
            Medical Aid Form
          </h1>
          <h2 className="text-base font-ibm-regular text-greyText">
            View filled medical aid form details
          </h2>
        </div>
        <div className="flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
          <Link to="/medical-aid">
            <Button className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-5 px-5 py-3 rounded-md border border-authBorder text-sm font-ibm-semibold text-black normal-case transition-all duration-300 ease-in-out">
              Back to Medical Aid Forms List
            </Button>
          </Link>
        </div>
      </div>

      {/* FORM */}
      <div className="container-full-width">
        <form className="p-0" onSubmit={formikForm.handleSubmit}>
          {/* PATIENT DETAILS */}
          <div className="mt-5">
            <div className="w-full bg-white border border-greyBorder rounded-xl p-5 xl:p-8 lg:p-5">
              <h5 className="text-xl font-ibm-bold text-black tracking-tight">
                Patient Details
              </h5>
              <div className="mt-3 grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-2 gap-5">
                <div className="col-span-3">
                  <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-5">
                    {/* DISTRICT */}
                    <div className="col-span-1 xl:col-span-2 lg:col-span-2">
                      <div className="form-group">
                        <label
                          className="block text-greyText text-sm font-ibm-regular"
                          htmlFor="lmpState"
                        >
                          Select District{" "}
                          <span className="text-red-500">*</span>
                        </label>
                        <div className="mt-1">
                          <Select
                            className="react-select"
                            classNamePrefix={
                              formikForm.errors.district &&
                              formikForm.touched.district
                                ? "dropdown_error"
                                : "dropdown"
                            }
                            isDisabled={true}
                            onChange={(e) => {
                              formikForm.setFieldValue("district", e);
                              formikForm.setFieldValue("zoneId", {
                                label: e?.zoneName,
                                value: e?.zoneId,
                              });
                            }}
                            value={formikForm.values.district}
                            options={regionsList}
                          />
                        </div>
                      </div>
                    </div>

                    {/* ZONE */}
                    <div className="col-span-1 xl:col-span-2 lg:col-span-2">
                      <label
                        className="block text-greyText text-sm font-ibm-regular"
                        htmlFor="zone"
                      >
                        Zone <span className="text-red-500">*</span>
                      </label>
                      <div className="mt-1">
                        <InputField
                          disabled={true}
                          type="text"
                          name="zoneId"
                          isDisabled={true}
                          error={
                            formikForm.errors.zoneId &&
                            formikForm.touched.zoneId
                          }
                          value={formikForm.values.zoneId?.label}
                          onChange={formikForm.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-span-1">
                  <label
                    className="block text-greyText text-sm font-ibm-regular"
                    htmlFor="firstName"
                  >
                    First name
                  </label>
                  <div className="mt-1">
                    <InputField
                      disabled={true}
                      type="text"
                      name="patientFirstName"
                      id="patientFirstName"
                      placeholder="First name"
                      error={
                        formikForm.errors.patientFirstName &&
                        formikForm.touched.patientFirstName
                      }
                      value={formikForm.values.patientFirstName}
                      onChange={formikForm.handleChange}
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label
                    className="block text-greyText text-sm font-ibm-regular"
                    htmlFor="middleName"
                  >
                    Middle/Father&apos;s/Husband&apos;s name
                  </label>
                  <div className="mt-1">
                    <InputField
                      disabled={true}
                      type="text"
                      name="patientMiddleName"
                      id="patientMiddleName"
                      placeholder="Middle/Father's/Husband's name"
                      error={
                        formikForm.errors.patientMiddleName &&
                        formikForm.touched.patientMiddleName
                      }
                      value={formikForm.values.patientMiddleName}
                      onChange={formikForm.handleChange}
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label
                    className="block text-greyText text-sm font-ibm-regular"
                    htmlFor="lastName"
                  >
                    Last name (Surname)
                  </label>
                  <div className="mt-1">
                    <InputField
                      disabled={true}
                      type="text"
                      name="patientLastName"
                      id="patientLastName"
                      placeholder="Last name"
                      error={
                        formikForm.errors.patientLastName &&
                        formikForm.touched.patientLastName
                      }
                      value={formikForm.values.patientLastName}
                      onChange={formikForm.handleChange}
                    />
                  </div>
                </div>
                <div className="col-span-1 xl:col-span-2 lg:col-span-1">
                  <label
                    className="block text-greyText text-sm font-ibm-regular"
                    htmlFor="address"
                  >
                    Address
                  </label>
                  <div className="mt-1">
                    <InputField
                      disabled={true}
                      type="text"
                      name="addressLineOne"
                      id="addressLineOne"
                      placeholder="Address"
                      error={
                        formikForm.errors.addressLineOne &&
                        formikForm.touched.addressLineOne
                      }
                      value={formikForm.values.addressLineOne}
                      onChange={formikForm.handleChange}
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label
                    className="block text-greyText text-sm font-ibm-regular"
                    htmlFor="pincode"
                  >
                    Pincode
                  </label>
                  <div className="mt-1">
                    <InputField
                      disabled={true}
                      type="number"
                      name="addressPinCode"
                      id="addressPinCode"
                      placeholder="Pincode"
                      error={
                        formikForm.errors.addressPinCode &&
                        formikForm.touched.addressPinCode
                      }
                      value={formikForm.values.addressPinCode}
                      onChange={formikForm.handleChange}
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label
                    className="block text-greyText text-sm font-ibm-regular"
                    htmlFor="nativePlace"
                  >
                    Native Place
                  </label>
                  <div className="mt-1">
                    <InputField
                      disabled={true}
                      type="text"
                      name="nativePlace"
                      id="nativePlace"
                      placeholder="Native Place"
                      error={
                        formikForm.errors.nativePlace &&
                        formikForm.touched.nativePlace
                      }
                      value={formikForm.values.nativePlace}
                      onChange={formikForm.handleChange}
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label
                    className="block text-greyText text-sm font-ibm-regular"
                    htmlFor="age"
                  >
                    Age
                  </label>
                  <div className="mt-1">
                    <InputField
                      disabled={true}
                      type="number"
                      name="patientAge"
                      id="patientAge"
                      placeholder="Age"
                      error={
                        formikForm.errors.patientAge &&
                        formikForm.touched.patientAge
                      }
                      value={formikForm.values.patientAge}
                      onChange={formikForm.handleChange}
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label
                    className="block text-greyText text-sm font-ibm-regular"
                    htmlFor="contact"
                  >
                    Contact No.
                  </label>
                  <div className="mt-1">
                    <PhoneInput
                      placeholder="Phone"
                      defaultCountry="IN"
                      disabled
                      className={`mt-1 ${formikForm.errors.phoneNumber &&
                        formikForm.touched.phoneNumber &&
                        "error"}`}
                      value={formikForm.values.phoneNumber}
                      onChange={(e) => {
                        formikForm.setFieldValue("phoneNumber", e);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-5 grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                <div className="col-span-1">
                  <label
                    className="block text-greyText text-sm font-ibm-regular"
                    htmlFor="annualIncomeAmount"
                  >
                    Annual Income of Household (₹)
                  </label>
                  <div className="mt-1">
                    <InputField
                      disabled={true}
                      type="number"
                      name="annualIncomeAmount"
                      id="annualIncomeAmount"
                      placeholder="Annual Income of Household"
                      error={
                        formikForm.errors.annualIncomeAmount &&
                        formikForm.touched.annualIncomeAmount
                      }
                      value={formikForm.values.annualIncomeAmount}
                      onChange={formikForm.handleChange}
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label
                    className="block text-greyText text-sm font-ibm-regular"
                    htmlFor="earningMemberCount"
                  >
                    Total no. of earning members in family
                  </label>
                  <div className="mt-1">
                    <InputField
                      disabled={true}
                      type="number"
                      name="earningMemberCount"
                      id="earningMemberCount"
                      placeholder="Total no. of earning members in family"
                      error={
                        formikForm.errors.earningMemberCount &&
                        formikForm.touched.earningMemberCount
                      }
                      value={formikForm.values.earningMemberCount}
                      onChange={formikForm.handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-5 grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                <div className="col-span-1">
                  <div className="form-group">
                    <label
                      className="block text-greyText text-sm font-ibm-regular"
                      htmlFor="photo"
                    >
                      Patient&apos;s profile photo
                    </label>
                    <div className="mt-1">
                      <ReactImageUploading
                        value={formikForm.values.photo}
                        onChange={(imageList) => {
                          formikForm.setFieldValue("photo", imageList);
                        }}
                        maxNumber={1}
                        dataURLKey="data_url"
                      >
                        {({ imageList, onImageUpload, onImageRemove }) => (
                          <div className="flex items-center gap-5 mt-2">
                            {/* IMAGE LIST */}
                            <div className="w-[15vh] h-auto relative">
                              <img
                                src={
                                  medicalAidFormDetails?.patientDetails
                                    ?.patientImage?.url
                                }
                                className="object-cover rounded-lg"
                                fill
                                alt="Uploaded"
                              />
                            </div>
                          </div>
                        )}
                      </ReactImageUploading>
                    </div>
                  </div>
                </div>
                <div className="col-span-1">
                  {medicalAidFormDetails?.patientDetails?.annualIncome?.file
                    ?.url && (
                    <Link
                      target="_blank"
                      to={
                        medicalAidFormDetails?.patientDetails?.annualIncome
                          ?.file?.url
                      }
                    >
                      <label
                        className="block text-greyText text-sm font-ibm-regular"
                        htmlFor="incomeProof"
                      >
                        Upload income proof of earning members
                      </label>
                      <div className="mt-1.5 w-[20vh] h-[15vh] bg-white hover:bg-red-400 hover:bg-opacity-10 border border-greyBorder hover:border-red-400 hover:border-opacity-30 rounded-lg p-5 flex-shrink-0 flex items-center justify-center relative transition-all duration-300 ease-in-out">
                        <div className="text-center">
                          {medicalAidFormDetails?.patientDetails?.annualIncome?.file?.url?.includes(
                            ".pdf"
                          ) ? (
                            <FaFilePdf className="w-8 h-8 text-[#f15642] mx-auto" />
                          ) : (
                            <IoDocumentText className="w-8 h-8 text-[#508EF5] mx-auto" />
                          )}

                          <p className="mt-3 text-sm font-ibm-regular text-greyText text-center">
                            Download the income proof document
                          </p>
                        </div>
                      </div>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* DISEASE DETAILS */}
          <div className="mt-5">
            <div className="w-full bg-white border border-greyBorder rounded-xl p-5 xl:p-8 lg:p-5">
              <h5 className="text-xl font-ibm-bold text-black tracking-tight">
                Medical Condition Details
              </h5>
              <div className="mt-3 grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-1 gap-5">
                <div className="col-span-1 xl:col-span-2 lg:col-span-1">
                  <label
                    className="block text-greyText text-sm font-ibm-regular"
                    htmlFor="medicalDetails.medicalCondition"
                  >
                    Name of Disease/Illness/Medical Condition
                  </label>
                  <div className="mt-1">
                    <InputField
                      disabled={true}
                      type="text"
                      name="medicalDetails.medicalCondition"
                      id="medicalDetails.medicalCondition"
                      placeholder="Name of Disease/Illness/Medical Condition"
                      error={
                        formikForm.errors?.medicalDetails?.medicalCondition &&
                        formikForm.touched?.medicalDetails?.medicalCondition
                      }
                      value={
                        formikForm.values?.medicalDetails?.medicalCondition
                      }
                      onChange={formikForm.handleChange}
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label
                    className="block text-greyText text-sm font-ibm-regular"
                    htmlFor="medicalDetails.sufferingSince"
                  >
                    Suffering since
                  </label>
                  <div className="mt-1">
                    <ReactDatePicker
                      selected={
                        formikForm?.values?.medicalDetails?.sufferingSince
                      }
                      onChange={(date) =>
                        formikForm.setFieldValue(
                          "medicalDetails.sufferingSince",
                          date
                        )
                      }
                      disabled
                      name="medicalDetails.sufferingSince"
                      dateFormat="dd/MM/yyyy"
                      className={`block w-full bg-white ${
                        formikForm?.errors?.medicalDetails?.sufferingSince &&
                        formikForm?.touched?.medicalDetails?.sufferingSince
                          ? "border-red-300"
                          : "border-greyBorder"
                      }`}
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label
                    className="block text-greyText text-sm font-ibm-regular"
                    htmlFor="medicalDetails.doctorName"
                  >
                    Doctor&apos;s name
                  </label>
                  <div className="mt-1">
                    <InputField
                      disabled={true}
                      type="text"
                      name="medicalDetails.doctorName"
                      id="medicalDetails.doctorName"
                      placeholder="Doctor name"
                      error={
                        formikForm?.errors?.medicalDetails?.doctorName &&
                        formikForm?.touched?.medicalDetails?.doctorName
                      }
                      value={formikForm?.values?.medicalDetails?.doctorName}
                      onChange={formikForm.handleChange}
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label
                    className="block text-greyText text-sm font-ibm-regular"
                    htmlFor="medicalDetails.hospitalName"
                  >
                    Hospital/Clinic name
                  </label>
                  <div className="mt-1">
                    <InputField
                      disabled={true}
                      type="text"
                      name="medicalDetails.hospitalName"
                      id="medicalDetails.hospitalName"
                      placeholder="Hospital name"
                      error={
                        formikForm?.errors?.medicalDetails?.hospitalName &&
                        formikForm?.touched?.medicalDetails?.hospitalName
                      }
                      value={formikForm?.values?.medicalDetails?.hospitalName}
                      onChange={formikForm.handleChange}
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label
                    className="block text-greyText text-sm font-ibm-regular"
                    htmlFor="medicalDetails.admittedAt"
                  >
                    Date when admitted
                  </label>
                  <div className="mt-1">
                    <ReactDatePicker
                      name="medicalDetails.admittedAt"
                      selected={formikForm?.values?.medicalDetails?.admittedAt}
                      disabled
                      onChange={(date) =>
                        formikForm.setFieldValue(
                          "medicalDetails.admittedAt",
                          date
                        )
                      }
                      dateFormat="dd/MM/yyyy"
                      className={`block ${
                        formikForm?.errors?.medicalDetails?.admittedAt &&
                        formikForm?.touched?.medicalDetails?.admittedAt
                          ? "border-red-300"
                          : "border-greyBorder"
                      }`}
                    />
                  </div>
                </div>
                <div className="col-span-1 xl:col-span-3 lg:col-span-1">
                  <label
                    className="block text-greyText text-sm font-ibm-regular"
                    htmlFor="medicalDetails.hospitalAddress"
                  >
                    Hospital/Clinic address
                  </label>
                  <div className="mt-1">
                    <TextareaField
                      rows={2}
                      name="medicalDetails.hospitalAddress"
                      id="medicalDetails.hospitalAddress"
                      placeholder="Hospital address"
                      error={
                        formikForm?.errors?.medicalDetails?.hospitalAddress &&
                        formikForm?.touched?.medicalDetails?.hospitalAddress
                      }
                      value={
                        formikForm?.values?.medicalDetails?.hospitalAddress
                      }
                      onChange={formikForm.handleChange}
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label
                    className="block text-greyText text-sm font-ibm-regular"
                    htmlFor="medicalDetails.costOfTreatment"
                  >
                    Total cost of treatment
                  </label>
                  <div className="mt-1">
                    <InputField
                      disabled={true}
                      type="number"
                      name="medicalDetails.costOfTreatment"
                      id="medicalDetails.costOfTreatment"
                      placeholder="Approx. cost of treatment"
                      error={
                        formikForm?.errors?.medicalDetails?.costOfTreatment &&
                        formikForm?.touched?.medicalDetails?.costOfTreatment
                      }
                      value={
                        formikForm?.values?.medicalDetails?.costOfTreatment
                      }
                      onChange={formikForm.handleChange}
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label
                    className="block text-greyText text-sm font-ibm-regular"
                    htmlFor="medicalDetails.careTakerName"
                  >
                    Name of caretaker/contact person for patient
                  </label>
                  <div className="mt-1">
                    <InputField
                      disabled={true}
                      type="text"
                      name="medicalDetails.careTakerName"
                      id="medicalDetails.careTakerName"
                      placeholder="Name of caretaker/contact person for patient"
                      error={
                        formikForm?.errors?.medicalDetails?.careTakerName &&
                        formikForm?.touched?.medicalDetails?.careTakerName
                      }
                      value={formikForm?.values?.medicalDetails?.careTakerName}
                      onChange={formikForm.handleChange}
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label
                    className="block text-greyText text-sm font-ibm-regular"
                    htmlFor="medicalDetails.careTakerPhoneNumber"
                  >
                    Caretaker/Contact person&apos;s mobile number
                  </label>
                  <div className="mt-1">
                    <PhoneInput
                      placeholder="Phone"
                      defaultCountry="IN"
                      className={`mt-1 ${
                        formikForm?.errors?.medicalDetails
                          ?.careTakerPhoneNumber &&
                        formikForm?.touched?.medicalDetails
                          ?.careTakerPhoneNumber
                          ? "error"
                          : ""
                      }`}
                      name="medicalDetails.careTakerPhoneNumber"
                      value={
                        formikForm?.values?.medicalDetails?.careTakerPhoneNumber
                      }
                      onChange={(e) =>
                        formikForm.setFieldValue(
                          "medicalDetails.careTakerPhoneNumber",
                          e
                        )
                      }
                    />
                  </div>
                </div>
                <div className="col-span-1 xl:col-span-2 lg:col-span-2">
                  <label
                    className="block text-greyText text-sm font-ibm-regular"
                    htmlFor="medicalDetails.healthInsuranceName"
                  >
                    Name and details of Mediclaim or Private / Goverment Health
                    Scheme / Health Insurance availed
                  </label>
                  <div className="mt-1">
                    <TextareaField
                      rows={2}
                      name="medicalDetails.healthInsuranceName"
                      id="medicalDetails.healthInsuranceName"
                      placeholder="Name and details of Mediclaim or Private / Goverment Health Scheme / Health Insurance availed"
                      error={
                        formikForm?.errors?.medicalDetails
                          ?.healthInsuranceName &&
                        formikForm?.touched?.medicalDetails?.healthInsuranceName
                      }
                      value={
                        formikForm?.values?.medicalDetails?.healthInsuranceName
                      }
                      onChange={formikForm.handleChange}
                    />
                  </div>
                </div>
                {/* AMOUNT AVAILED */}
                <div className="col-span-1">
                  <label
                    className="block text-greyText text-sm font-ibm-regular"
                    htmlFor="medicalDetails.healthInsuranceAmountAvailed"
                  >
                    Amount Availed (₹)
                  </label>
                  <div className="mt-1">
                    <InputField
                      disabled={true}
                      type="text"
                      name="medicalDetails.healthInsuranceAmountAvailed"
                      id="medicalDetails.healthInsuranceAmountAvailed"
                      placeholder="Amount Availed"
                      error={
                        formikForm?.errors?.medicalDetails
                          ?.healthInsuranceAmountAvailed &&
                        formikForm?.touched?.medicalDetails
                          ?.healthInsuranceAmountAvailed
                      }
                      value={
                        formikForm?.values?.medicalDetails
                          ?.healthInsuranceAmountAvailed
                      }
                      onChange={formikForm.handleChange}
                    />
                  </div>
                </div>
                <div className="col-span-1 xl:col-span-2 lg:col-span-2">
                  <label
                    className="block text-greyText text-sm font-ibm-regular"
                    htmlFor="medicalDetails.additionalFundsAvailed"
                  >
                    Name and details of any additional funds or assistance
                    availed from any organization
                  </label>
                  <div className="mt-1">
                    <TextareaField
                      name="medicalDetails.additionalFundsAvailed"
                      id="medicalDetails.additionalFundsAvailed"
                      placeholder="Amount Availed"
                      error={
                        formikForm?.errors?.medicalDetails
                          ?.additionalFundsAvailed &&
                        formikForm?.touched?.medicalDetails
                          ?.additionalFundsAvailed
                      }
                      value={
                        formikForm?.values?.medicalDetails
                          ?.additionalFundsAvailed
                      }
                      onChange={formikForm.handleChange}
                    />
                  </div>
                </div>
                {/* AMOUNT AVAILED */}
                <div className="col-span-1">
                  <label
                    className="block text-greyText text-sm font-ibm-regular"
                    htmlFor="medicalDetails.additionalFundsAmountAvailed"
                  >
                    Amount Availed (₹)
                  </label>
                  <div className="mt-1">
                    <InputField
                      disabled={true}
                      type="text"
                      name="medicalDetails.additionalFundsAmountAvailed"
                      id="medicalDetails.additionalFundsAmountAvailed"
                      placeholder="Amount Availed"
                      error={
                        formikForm?.errors?.medicalDetails
                          ?.additionalFundsAmountAvailed &&
                        formikForm?.touched?.medicalDetails
                          ?.additionalFundsAmountAvailed
                      }
                      value={
                        formikForm?.values?.medicalDetails
                          ?.additionalFundsAmountAvailed
                      }
                      onChange={formikForm.handleChange}
                    />
                  </div>
                </div>
                <div className="col-span-1 xl:col-span-2 lg:col-span-2">
                  <label
                    className="block text-greyText text-sm font-ibm-regular"
                    htmlFor="medicalDetails.previousMedicalAidFromLMP"
                  >
                    Details of any previous medical aid received from Shree
                    Lohana Mahaparishad
                  </label>
                  <div className="mt-1">
                    <TextareaField
                      rows={2}
                      name="medicalDetails.previousMedicalAidFromLMP"
                      id="medicalDetails.previousMedicalAidFromLMP"
                      placeholder="Details of any previous medical aid received from Shree Lohana Mahaparishad"
                      error={
                        formikForm?.errors?.medicalDetails
                          ?.previousMedicalAidFromLMP &&
                        formikForm?.touched?.medicalDetails
                          ?.previousMedicalAidFromLMP
                      }
                      value={
                        formikForm?.values?.medicalDetails
                          ?.previousMedicalAidFromLMP
                      }
                      onChange={formikForm.handleChange}
                    />
                  </div>
                </div>
                {/* AMOUNT AVAILED */}
                <div className="col-span-1">
                  <label
                    className="block text-greyText text-sm font-ibm-regular"
                    htmlFor="medicalDetails.previousMedicalAidFromLMPAmountAvailed"
                  >
                    Amount Availed (₹)
                  </label>
                  <div className="mt-1">
                    <InputField
                      disabled={true}
                      type="text"
                      name="medicalDetails.previousMedicalAidFromLMPAmountAvailed"
                      id="medicalDetails.previousMedicalAidFromLMPAmountAvailed"
                      placeholder="Amount Availed"
                      error={
                        formikForm?.errors?.medicalDetails
                          ?.previousMedicalAidFromLMPAmountAvailed &&
                        formikForm?.touched?.medicalDetails
                          ?.previousMedicalAidFromLMPAmountAvailed
                      }
                      value={
                        formikForm?.values?.medicalDetails
                          ?.previousMedicalAidFromLMPAmountAvailed
                      }
                      onChange={formikForm.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-5 grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                <div className="col-span-1">
                  {medicalAidFormDetails?.medicalDetails?.estimatedTreatmentCost
                    ?.url && (
                    <Link
                      target="_blank"
                      to={
                        medicalAidFormDetails?.medicalDetails
                          ?.estimatedTreatmentCost?.url
                      }
                    >
                      <label
                        className="block text-greyText text-sm font-ibm-regular"
                        htmlFor="incomeProof"
                      >
                        Upload treatment cost estimate (on hospital/clinic
                        letterhead)
                      </label>
                      <div className="mt-1.5 w-[20vh] h-[15vh] bg-white hover:bg-red-400 hover:bg-opacity-10 border border-greyBorder hover:border-red-400 hover:border-opacity-30 rounded-lg p-5 flex-shrink-0 flex items-center justify-center relative transition-all duration-300 ease-in-out">
                        <div className="text-center">
                          {medicalAidFormDetails?.medicalDetails?.estimatedTreatmentCost?.url?.includes(
                            ".pdf"
                          ) ? (
                            <FaFilePdf className="w-8 h-8 text-[#f15642] mx-auto" />
                          ) : (
                            <IoDocumentText className="w-8 h-8 text-[#508EF5] mx-auto" />
                          )}

                          <p className="mt-3 text-sm font-ibm-regular text-greyText text-center">
                            Treatment Cost Estimate Document
                          </p>
                        </div>
                      </div>
                    </Link>
                  )}
                </div>
                <div className="col-span-1">
                  {medicalAidFormDetails?.medicalDetails?.reports?.url && (
                    <Link
                      target="_blank"
                      to={medicalAidFormDetails?.medicalDetails?.reports?.url}
                    >
                      <label
                        className="block text-greyText text-sm font-ibm-regular"
                        htmlFor="incomeProof"
                      >
                        Uploaded Reports
                      </label>
                      <div className="mt-1.5 w-[20vh] h-[15vh] bg-white hover:bg-red-400 hover:bg-opacity-10 border border-greyBorder hover:border-red-400 hover:border-opacity-30 rounded-lg p-5 flex-shrink-0 flex items-center justify-center relative transition-all duration-300 ease-in-out">
                        <div className="text-center">
                          {medicalAidFormDetails?.medicalDetails?.reports?.url?.includes(
                            ".pdf"
                          ) ? (
                            <FaFilePdf className="w-8 h-8 text-[#f15642] mx-auto" />
                          ) : (
                            <IoDocumentText className="w-8 h-8 text-[#508EF5] mx-auto" />
                          )}

                          <p className="mt-3 text-sm font-ibm-regular text-greyText text-center">
                            Report Documents
                          </p>
                        </div>
                      </div>
                    </Link>
                  )}
                </div>
                <div className="col-span-1">
                  {medicalAidFormDetails?.recommendations?.url && (
                    <Link
                      target="_blank"
                      to={medicalAidFormDetails?.recommendations?.url}
                    >
                      <label
                        className="block text-greyText text-sm font-ibm-regular"
                        htmlFor="incomeProof"
                      >
                        Uploaded Recommendation Letter
                      </label>
                      <div className="mt-1.5 w-[20vh] h-[15vh] bg-white hover:bg-red-400 hover:bg-opacity-10 border border-greyBorder hover:border-red-400 hover:border-opacity-30 rounded-lg p-5 flex-shrink-0 flex items-center justify-center relative transition-all duration-300 ease-in-out">
                        <div className="text-center">
                          {medicalAidFormDetails?.recommendations?.url?.includes(
                            ".pdf"
                          ) ? (
                            <FaFilePdf className="w-8 h-8 text-[#f15642] mx-auto" />
                          ) : (
                            <IoDocumentText className="w-8 h-8 text-[#508EF5] mx-auto" />
                          )}

                          <p className="mt-3 text-sm font-ibm-regular text-greyText text-center">
                            Recommendation Documents
                          </p>
                        </div>
                      </div>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* FAMILY DETAILS */}
          {familyDetails[0].familyMember !== "" && (
            <div className="w-full bg-white border border-greyBorder rounded-xl p-5 xl:p-8 lg:p-8 mt-5">
              <h5 className="text-xl font-ibm-bold text-black tracking-tight">
                Family Details
              </h5>
              <div className="mt-3">
                <DataTable
                  columns={columns}
                  data={familyDetails?.filter(
                    (data) => data.familyMember !== ""
                  )}
                  responsive
                  className="family"
                />
              </div>
            </div>
          )}

          {/* BANK DETAILS */}
          <div className="mt-5">
            <div className="w-full bg-white border border-greyBorder rounded-xl p-5 xl:p-8 lg:p-8">
              <h5 className="text-xl font-ibm-bold text-black tracking-tight">
                Aid Disbursement Details
              </h5>
              <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5 mt-3">
                <div className="col-span-1 xl:col-span-2 lg:col-span-2">
                  <div className="flex items-center gap-1">
                    <label
                      className="block text-greyText font-ibm-regular text-sm"
                      htmlFor="bankDetails.accountHolder"
                    >
                      Name of Beneficiary Account Holder (to be written on
                      cheque)
                    </label>
                    <Tooltip
                      content="Name must be of the Hospital or Clinic only. Cheque will not be issued in the name of patient or relative."
                      className="w-1/4 text-sm font-ibm-semibold text-white text-center"
                    >
                      <Button className="p-0 bg-transparent shadow-none hover:shadow-none">
                        <FaInfoCircle className="w-4 h-4 text-greyText text-opacity-50" />
                      </Button>
                    </Tooltip>
                  </div>
                  <div className="mt-1">
                    <InputField
                      disabled={true}
                      type="text"
                      name="bankDetails.accountHolder"
                      id="bankDetails.accountHolder"
                      placeholder="Name of Beneficiary Account Holder"
                      error={
                        formikForm?.errors?.bankDetails?.accountHolder &&
                        formikForm?.touched?.bankDetails?.accountHolder
                      }
                      value={formikForm?.values?.bankDetails?.accountHolder}
                      onChange={formikForm.handleChange}
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label
                    className="block text-greyText font-ibm-regular text-sm"
                    htmlFor="bankDetails.bankName"
                  >
                    Bank name
                  </label>
                  <div className="mt-1">
                    <InputField
                      disabled={true}
                      type="text"
                      name="bankDetails.bankName"
                      id="bankDetails.bankName"
                      placeholder="Bank name"
                      error={
                        formikForm?.errors?.bankDetails?.bankName &&
                        formikForm?.touched?.bankDetails?.bankName
                      }
                      value={formikForm?.values?.bankDetails?.bankName}
                      onChange={formikForm.handleChange}
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label
                    className="block text-greyText font-ibm-regular text-sm"
                    htmlFor="bankDetails.branch"
                  >
                    Branch
                  </label>
                  <div className="mt-1">
                    <InputField
                      disabled={true}
                      type="text"
                      name="bankDetails.branch"
                      id="bankDetails.branch"
                      placeholder="Branch"
                      error={
                        formikForm?.errors?.bankDetails?.branch &&
                        formikForm?.touched?.bankDetails?.branch
                      }
                      value={formikForm?.values?.bankDetails?.branch}
                      onChange={formikForm.handleChange}
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label
                    className="block text-greyText font-ibm-regular text-sm"
                    htmlFor="bankDetails.accountNumber"
                  >
                    Bank A/c. No
                  </label>
                  <div className="mt-1">
                    <InputField
                      disabled={true}
                      type="number"
                      name="bankDetails.accountNumber"
                      id="bankDetails.accountNumber"
                      placeholder="Bank account no."
                      error={
                        formikForm?.errors?.bankDetails?.accountNumber &&
                        formikForm?.touched?.bankDetails?.accountNumber
                      }
                      value={formikForm?.values?.bankDetails?.accountNumber}
                      onChange={formikForm.handleChange}
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label
                    className="block text-greyText font-ibm-regular text-sm"
                    htmlFor="bankDetails.ifscCode"
                  >
                    IFSC code
                  </label>
                  <div className="mt-1">
                    <InputField
                      disabled={true}
                      type="text"
                      name="bankDetails.ifscCode"
                      id="bankDetails.ifscCode"
                      placeholder="IFSC code"
                      error={
                        formikForm?.errors?.bankDetails?.ifscCode &&
                        formikForm?.touched?.bankDetails?.ifscCode
                      }
                      value={formikForm?.values?.bankDetails?.ifscCode}
                      onChange={formikForm.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="block xl:flex lg:flex items-end justify-between mt-5">
                <div className="form-group">
                  <label
                    className="block text-greyText font-ibm-regular text-sm"
                    htmlFor="bankDetails.date"
                  >
                    Date
                  </label>
                  <div className="mt-1">
                    <ReactDatePicker
                      selected={formikForm?.values?.bankDetails?.date}
                      onChange={(date) =>
                        formikForm.setFieldValue("bankDetails.date", date)
                      }
                      disabled
                      name="bankDetails.date"
                      dateFormat="dd/MM/yyyy"
                      className={`block w-full bg-white`}
                    />
                  </div>
                </div>
                <div className="form-group mt-5 xl:mt-0 lg:mt-0">
                  <label
                    className="block text-greyText font-ibm-regular text-sm"
                    htmlFor="branch"
                  >
                    Signature
                    <span className="text-red-500">*</span>
                  </label>
                  <div
                    className={
                      formikForm?.errors?.bankDetails?.signature &&
                      formikForm?.touched?.bankDetails?.signature
                        ? "border bg-white border-red-500 rounded-lg mt-1"
                        : "border bg-white  rounded-lg mt-1"
                    }
                  >
                    <img
                      src={medicalAidFormDetails?.bankDetails?.signature?.url}
                      alt={"signature"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* MAHAJAN DETAILS */}
          <div className="mt-5 w-full bg-white border border-greyBorder rounded-xl shadow p-5 xl:p-8 lg:p-8">
            <h5 className="text-xl font-ibm-bold text-black tracking-tight">
              Mahajan Details
            </h5>
            <div className="mt-3">
              <div className="col-span-1">
                <label
                  className="block text-greyText font-ibm-regular text-sm"
                  htmlFor="mahajanDetails.nearestMahajan"
                >
                  Name of Nearest Mahajan
                </label>
                <div className="mt-1">
                  <InputField
                    type="text"
                    name="mahajanDetails.nearestMahajan"
                    id="mahajanDetails.nearestMahajan"
                    placeholder="Name of Nearest Mahajan"
                    error={
                      formikForm?.errors?.mahajanDetails?.nearestMahajan &&
                      formikForm?.touched?.mahajanDetails?.nearestMahajan
                    }
                    disabled={true}
                    value={
                      medicalAidFormDetails?.mahajanDetails?.nearestMahajan
                    }
                    onChange={formikForm.handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div className="p-0">
                <p className="text-sm font-ibm-regular text-greyText">To,</p>
                <p className="mt-1 text-sm font-ibm-regular text-greyText">
                  The Chairman,
                </p>
                <p className="mt-1 text-sm font-ibm-regular text-greyText">
                  Healthcare Committee,
                </p>
                <p className="mt-1 text-sm font-ibm-regular text-greyText">
                  Shree Lohana Mahaparishad
                </p>
              </div>
              <div className="mt-5">
                <div className="flex items-center gap-3 flex-wrap xl:flex-nowrap lg:flex-wrap">
                  <p className="text-sm font-ibm-regular text-black flex-shrink-0">
                    This is to certify that Mr/Mrs
                  </p>
                  <input
                    type="text"
                    name="mahajanDetails.name"
                    id="mahajanDetails.name"
                    placeholder="Name"
                    error={
                      formikForm?.errors?.mahajanDetails?.name &&
                      formikForm?.touched?.mahajanDetails?.name
                    }
                    disabled={true}
                    value={medicalAidFormDetails?.mahajanDetails?.name}
                    onChange={formikForm.handleChange}
                    className="w-full block outline-none focus:outline-none bg-white border-b border-greyBorder rounded-none px-2 py-2 font-ibm-regular text-black placeholder:font-ibm-regular text-sm"
                  />
                  <p className="text-sm font-ibm-regular text-black flex-shrink-0">
                    aged
                  </p>
                  <input
                    type="number"
                    name="mahajanDetails.age"
                    id="mahajanDetails.age"
                    error={
                      formikForm?.errors?.mahajanDetails?.age &&
                      formikForm?.touched?.mahajanDetails?.age
                    }
                    disabled={true}
                    value={medicalAidFormDetails?.mahajanDetails?.age}
                    onChange={formikForm.handleChange}
                    placeholder="Age"
                    className="w-full block outline-none focus:outline-none bg-white border-b border-greyBorder rounded-none px-2 py-2 font-ibm-regular text-black placeholder:font-ibm-regular text-sm"
                  />
                  <p className="text-sm font-ibm-regular text-black flex-shrink-0">
                    living in
                  </p>
                  <input
                    type="text"
                    name="mahajanDetails.livingIn"
                    id="mahajanDetails.livingIn"
                    error={
                      formikForm?.errors?.mahajanDetails?.livingIn &&
                      formikForm?.touched?.mahajanDetails?.livingIn
                    }
                    disabled={true}
                    value={medicalAidFormDetails?.mahajanDetails?.livingIn}
                    onChange={formikForm.handleChange}
                    placeholder="Living in"
                    className="w-full block outline-none focus:outline-none bg-white border-b border-greyBorder rounded-none px-2 py-2 font-ibm-semibold text-black placeholder:font-ibm-regular text-sm"
                  />
                  <p className="text-sm font-ibm-regular text-black flex-shrink-0">
                    (city/town/village) undergoing treatment for
                  </p>
                </div>
                <div className="flex items-center gap-3 flex-wrap xl:flex-nowrap lg:flex-wrap mt-3">
                  <input
                    type="text"
                    name="mahajanDetails.illness"
                    id="mahajanDetails.illness"
                    error={
                      formikForm?.errors?.mahajanDetails?.illness &&
                      formikForm?.touched?.mahajanDetails?.illness
                    }
                    disabled={true}
                    value={medicalAidFormDetails?.mahajanDetails?.illness}
                    onChange={formikForm.handleChange}
                    placeholder="Illness name"
                    className="w-full block outline-none focus:outline-none bg-white border-b border-greyBorder rounded-none px-2 py-2 font-ibm-semibold text-black placeholder:font-ibm-regular text-sm"
                  />
                  <p className="text-sm font-ibm-regular text-black flex-shrink xl:flex-shrink-0 lg:flex-shrink">
                    is known to us. Since their financial condition is weak, we
                    request you to kindly provide monetary assistance to them.
                  </p>
                </div>
              </div>
              <div className="block xl:flex lg:flex items-end justify-between mt-5 xl:mt-8 lg:mt-5">
                <div className="form-group">
                  <label className="block text-greyText text-sm font-ibm-regular">
                    Stamp/Seal of Mahajan
                  </label>
                  {medicalAidFormDetails?.mahajanDetails?.stamp?.url ? (
                    <div className="border bg-white mt-1">
                      <div className="col-span-1">
                        <Link
                          target="_blank"
                          to={medicalAidFormDetails?.mahajanDetails?.stamp?.url}
                        >
                          <label
                            className="block text-black text-sm font-ibm-semibold"
                            htmlFor="incomeProof"
                          >
                            Uploaded Stamp
                          </label>
                          <div className="w-[15vh] h-[15vh] border border-greyBorder rounded-lg p-5 flex-shrink-0 flex items-center justify-center relative">
                            <div className="text-center">
                              {medicalAidFormDetails?.mahajanDetails?.stamp?.url?.includes(
                                ".pdf"
                              ) ? (
                                <FaFilePdf className="w-6 h-6 text-[#f15642] mx-auto" />
                              ) : (
                                <IoDocumentText className="w-6 h-6 text-[#508EF5] mx-auto" />
                              )}

                              <p className="mt-3 text-xs font-ibm-semibold text-greyText leading-tight text-center">
                                Stamp Documents
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <p className="mt-1.5 text-sm font-ibm-semibold text-black">
                      No stamp/seal found
                    </p>
                  )}
                </div>
                <div className="form-group mt-5 xl:mt-0 lg:mt-0">
                  <label className="block text-greyText text-sm font-ibm-regular">
                    Signature of President/Secretary
                  </label>
                  {medicalAidFormDetails?.mahajanDetails?.signature?.url ? (
                    <div className="border bg-white mt-1">
                      <div className="col-span-1">
                        <Link
                          target="_blank"
                          to={
                            medicalAidFormDetails?.mahajanDetails?.signature
                              ?.url
                          }
                        >
                          <label
                            className="block text-black text-sm font-ibm-semibold"
                            htmlFor="incomeProof"
                          >
                            Uploaded Signature
                          </label>
                          <div className="w-[15vh] h-[15vh] border border-greyBorder rounded-lg p-5 flex-shrink-0 flex items-center justify-center relative">
                            <div className="text-center">
                              {medicalAidFormDetails?.mahajanDetails?.signature?.url?.includes(
                                ".pdf"
                              ) ? (
                                <FaFilePdf className="w-6 h-6 text-[#f15642] mx-auto" />
                              ) : (
                                <IoDocumentText className="w-6 h-6 text-[#508EF5] mx-auto" />
                              )}

                              <p className="mt-3 text-xs font-ibm-semibold text-greyText leading-tight text-center">
                                Signature Documents
                              </p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <p className="mt-1.5 text-sm font-ibm-semibold text-black">
                      No signature found
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* ZONAL */}
          <div className="mt-5 w-full bg-white border border-greyBorder rounded-xl shadow p-5 xl:p-8 lg:p-8">
            <h5 className="text-xl font-ibm-bold text-black tracking-tight">
              Recommendation of Zonal/Regional Office Bearer of Shree Lohana
              Mahaparishad
            </h5>
            <div className="mt-3 grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
              <div className="col-span-1">
                <label
                  className="block text-greyText text-sm font-ibm-regular"
                  htmlFor="zonalPresident"
                >
                  Name of Zonal/Regional President or Secretary
                </label>
                <div className="mt-1">
                  <InputField
                    type="text"
                    name="zonalRegionOfficeDetails.name"
                    id="zonalRegionOfficeDetails.name"
                    error={
                      formikForm?.errors?.zonalRegionOfficeDetails?.name &&
                      formikForm?.touched?.zonalRegionOfficeDetails?.name
                    }
                    disabled={true}
                    value={
                      medicalAidFormDetails?.zonalRegionOfficeDetails?.name
                    }
                    onChange={formikForm.handleChange}
                    placeholder="Name of Zonal/Regional President or Secretary"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <label
                  className="block text-greyText text-sm font-ibm-regular"
                  htmlFor="designationMahajan"
                >
                  Details of designation held in local Mahajan (if any)
                </label>
                <div className="mt-1">
                  <InputField
                    type="number"
                    name="zonalRegionOfficeDetails.designation"
                    id="zonalRegionOfficeDetails.designation"
                    error={
                      formikForm?.errors?.zonalRegionOfficeDetails
                        ?.designation &&
                      formikForm?.touched?.zonalRegionOfficeDetails?.designation
                    }
                    disabled={true}
                    value={
                      medicalAidFormDetails?.zonalRegionOfficeDetails
                        ?.designation
                    }
                    onChange={formikForm.handleChange}
                    placeholder="Details of designation held in local Mahajan (if any)"
                  />
                </div>
              </div>
              <div className="col-span-1 xl:col-span-2 lg:col-span-2">
                <label
                  className="block text-greyText text-sm font-ibm-regular"
                  htmlFor="mahajanAddress"
                >
                  Address
                </label>
                <div className="mt-1">
                  <TextareaField
                    rows={2}
                    name="zonalRegionOfficeDetails.address"
                    id="zonalRegionOfficeDetails.address"
                    error={
                      formikForm?.errors?.zonalRegionOfficeDetails?.address &&
                      formikForm?.touched?.zonalRegionOfficeDetails?.address
                    }
                    disabled={true}
                    value={
                      medicalAidFormDetails?.zonalRegionOfficeDetails?.address
                    }
                    onChange={formikForm.handleChange}
                    placeholder="Address"
                  />
                </div>
              </div>
            </div>
            <div className="block xl:flex lg:flex items-end justify-between mt-5">
              <div className="form-group">
                <label
                  className="block text-greyText text-sm font-ibm-regular"
                  htmlFor="mahaparishadDesignation"
                >
                  Designation in Mahaparishad
                </label>
                <div className="mt-1 w-full xl:w-[30vh] lg:w-[30vh]">
                  <InputField
                    type="text"
                    name="zonalRegionOfficeDetails.designationInMahaparishad"
                    id="zonalRegionOfficeDetails.designationInMahaparishad"
                    error={
                      formikForm?.errors?.zonalRegionOfficeDetails
                        ?.designationInMahaparishad &&
                      formikForm?.touched?.zonalRegionOfficeDetails
                        ?.designationInMahaparishad
                    }
                    disabled={true}
                    value={
                      medicalAidFormDetails?.zonalRegionOfficeDetails
                        ?.designationInMahaparishad
                    }
                    onChange={formikForm.handleChange}
                    placeholder="Designation in Mahaparishad"
                  />
                </div>
              </div>
              <div className="form-group mt-5 xl:mt-0 lg:mt-0">
                <label
                  className="block text-greyText text-sm font-ibm-regular"
                  htmlFor="signnature"
                >
                  Signature
                </label>
                {medicalAidFormDetails?.zonalRegionOfficeDetails?.signature
                  ?.url ? (
                  <div className="border bg-white mt-1">
                    <div className="col-span-1">
                      {medicalAidFormDetails?.zonalRegionOfficeDetails
                        ?.signature?.url && (
                        <Link
                          target="_blank"
                          to={
                            medicalAidFormDetails?.zonalRegionOfficeDetails
                              ?.signature?.url
                          }
                        >
                          <label
                            className="block text-black text-sm font-ibm-semibold"
                            htmlFor="incomeProof"
                          >
                            Uploaded Signature
                          </label>
                          <div className="w-[15vh] h-[15vh] border border-greyBorder rounded-lg p-5 flex-shrink-0 flex items-center justify-center relative">
                            <div className="text-center">
                              {medicalAidFormDetails?.zonalRegionOfficeDetails?.signature?.url?.includes(
                                ".pdf"
                              ) ? (
                                <FaFilePdf className="w-6 h-6 text-[#f15642] mx-auto" />
                              ) : (
                                <IoDocumentText className="w-6 h-6 text-[#508EF5] mx-auto" />
                              )}

                              <p className="mt-3 text-xs font-ibm-semibold text-greyText leading-tight text-center">
                                Signature Documents
                              </p>
                            </div>
                          </div>
                        </Link>
                      )}
                    </div>
                  </div>
                ) : (
                  <p className="mt-1.5 text-sm font-ibm-semibold text-black">
                    No signature found
                  </p>
                )}
              </div>
            </div>
          </div>

          {/* ONLY FOR OFFICE USE */}
          <div className="mt-5 w-full bg-white border border-greyBorder rounded-xl shadow p-5 xl:p-8 lg:p-8">
            <h5 className="text-xl font-ibm-bold text-black tracking-tight">
              Only For Office Use
            </h5>
            <div className="mt-3 grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-5">
              {/* INTERVIEW DATE */}
              <div className="col-span-1">
                <label
                  className="block text-greyText text-sm font-ibm-regular"
                  htmlFor="zonalPresident"
                >
                  Interview Date
                </label>
                <div className="mt-1">
                  <ReactDatePicker
                    selected={
                      medicalAidFormDetails?.officeDetails?.interviewDate
                    }
                    onChange={(date) =>
                      formikForm.setFieldValue(
                        "officeDetails.interviewDate",
                        date
                      )
                    }
                    disabled={true}
                    placeholderText="Interview Date"
                    name="officeDetails.interviewDate"
                    dateFormat="dd/MM/yyyy"
                    className={`block w-full bg-white ${
                      formikForm?.errors?.officeDetails?.interviewDate &&
                      formikForm?.touched?.officeDetails?.interviewDate
                        ? "border-red-300"
                        : "border-none"
                    } border rounded-base  font-ibm-semibold placeholder:font-ibm-regular text-sm placeholder:text-sm`}
                  />
                </div>
              </div>

              {/* APPROVED AID AMOUNT */}
              <div className="col-span-1">
                <label
                  className="block text-greyText text-sm font-ibm-regular"
                  htmlFor="designationMahajan"
                >
                  Granted Assistance
                </label>
                <div className="mt-1">
                  <InputField
                    type="text"
                    name="officeDetails.grantedAssistance"
                    id="officeDetails.grantedAssistance"
                    error={
                      formikForm?.errors?.officeDetails?.grantedAssistance &&
                      formikForm?.touched?.officeDetails?.grantedAssistance
                    }
                    disabled={true}
                    value={
                      medicalAidFormDetails?.officeDetails?.grantedAssistance
                    }
                    placeholder="Granted Assistance"
                  />
                </div>
              </div>

              {/* INTERVIEW NAME */}
              <div className="col-span-1 xl:col-span-2 lg:col-span-2">
                <label
                  className="block text-greyText text-sm font-ibm-regular"
                  htmlFor="mahajanAddress"
                >
                  Interview Name
                </label>
                <div className="mt-1">
                  <InputField
                    type="text"
                    name="officeDetails.interviewName"
                    id="officeDetails.interviewName"
                    error={
                      formikForm?.errors?.officeDetails?.interviewName &&
                      formikForm?.touched?.officeDetails?.interviewName
                    }
                    disabled={true}
                    value={medicalAidFormDetails?.officeDetails?.interviewName}
                    onChange={formikForm.handleChange}
                    placeholder="Name of Nearest Mahajan"
                  />
                </div>
              </div>

              {/* NAME ON CHECK */}
              <div className="col-span-1 xl:col-span-2 lg:col-span-2">
                <label
                  className="block text-greyText text-sm font-ibm-regular"
                  htmlFor="mahajanAddress"
                >
                  Name on the Check
                </label>
                <div className="mt-1">
                  <InputField
                    type="text"
                    name="officeDetails.chequeDetailsName"
                    id="officeDetails.chequeDetailsName"
                    error={
                      formikForm?.errors?.officeDetails?.chequeDetailsName &&
                      formikForm?.touched?.officeDetails?.chequeDetailsName
                    }
                    disabled={true}
                    value={
                      medicalAidFormDetails?.officeDetails?.chequeDetails?.name
                    }
                    onChange={formikForm.handleChange}
                    placeholder="Name on Cheque"
                  />
                </div>
              </div>

              {/* AMOUNT */}
              <div className="col-span-1">
                <div className="form-group block">
                  <label
                    className="block text-greyText text-sm font-ibm-regular"
                    htmlFor="mahaparishadDesignation"
                  >
                    Amount
                  </label>
                  <div className="mt-1 w-full xl:w-[30vh] lg:w-[30vh]">
                    <InputField
                      type="number"
                      name="officeDetails.chequeDetailsAmount"
                      id="officeDetails.chequeDetailsAmount"
                      error={
                        formikForm?.errors?.officeDetails
                          ?.chequeDetailsAmount &&
                        formikForm?.touched?.officeDetails?.chequeDetailsAmount
                      }
                      disabled={true}
                      value={
                        medicalAidFormDetails?.officeDetails?.chequeDetails
                          ?.amount
                      }
                      onChange={formikForm.handleChange}
                      placeholder="Amount"
                    />
                  </div>
                </div>
              </div>

              {/* DATE */}
              <div className="col-span-1">
                <label
                  className="block text-greyText text-sm font-ibm-regular"
                  htmlFor="medicalDetails.admittedAt"
                >
                  Date
                </label>
                <div className="mt-1">
                  <ReactDatePicker
                    name="officeDetails.chequeDetailsDate"
                    disabled={true}
                    selected={
                      medicalAidFormDetails?.officeDetails?.chequeDetails?.date
                    }
                    onChange={(date) =>
                      formikForm.setFieldValue(
                        "officeDetails.chequeDetailsDate",
                        date
                      )
                    }
                    dateFormat="dd/MM/yyyy"
                    className={`block w-full bg-white ${
                      formikForm?.errors?.officeDetails?.chequeDetailsDate &&
                      formikForm?.touched?.officeDetails?.chequeDetailsDate
                        ? "border-red-300"
                        : "border-none"
                    } border rounded-lg px-0 py-0 font-ibm-semibold placeholder:font-ibm-regular text-sm placeholder:text-sm`}
                  />
                </div>
              </div>
            </div>
            <div className="mt-3 grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-5">
              <div className="col-span-1">
                {medicalAidFormDetails?.officeDetails?.signature?.url && (
                  <Link
                    target="_blank"
                    to={medicalAidFormDetails?.officeDetails?.signature?.url}
                  >
                    <label
                      className="block text-black text-sm font-ibm-semibold"
                      htmlFor="incomeProof"
                    >
                      Uploaded Signature
                    </label>
                    <div className="w-[15vh] h-[15vh] border border-greyBorder rounded-lg p-5 flex-shrink-0 flex items-center justify-center relative">
                      <div className="text-center">
                        {medicalAidFormDetails?.officeDetails?.signature?.url?.includes(
                          ".pdf"
                        ) ? (
                          <FaFilePdf className="w-6 h-6 text-[#f15642] mx-auto" />
                        ) : (
                          <IoDocumentText className="w-6 h-6 text-[#508EF5] mx-auto" />
                        )}

                        <p className="mt-3 text-xs font-ibm-semibold text-greyText leading-tight text-center">
                          Signature Documents
                        </p>
                      </div>
                    </div>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}
