import { Link } from "react-router-dom";

/* ICONS */
import { TbBrandGoogleHome } from "react-icons/tb";

/* ASSETS */
import Logo from "assets/images/logo.png";
import {
  IconAd,
  IconAddressBook,
  IconFileSymlink,
  IconHeadphones,
  IconMail,
  IconUsers,
} from "@tabler/icons-react";

const Sidebar = () => {
  var pathname = window.location.pathname;

  return (
    <section className="h-screen w-sidebar bg-black transition-all duration-300 ease-linear">
      {/* HEADER */}
      <div className="h-header border-b border-white border-opacity-20 px-5 flex items-center">
        <img
          src={Logo}
          className="w-14 h-auto object-contain"
          alt="Lohana Mahaparishad"
        />
      </div>

      {/* NAVIGATION OPTIONS */}
      <div className="h-[90vh] flex flex-col justify-between">
        <div className="p-5">
          {/* MANAGE DETAILS */}
          <div className="mt-0">
            <h5 className="text-xs font-ibm-semibold text-white text-opacity-50 uppercase">
              Manage Details
            </h5>

            {/* ORGANIZATION */}
            <Link
              to={"/organization"}
              className="w-full group flex items-center gap-3 first-of-type:mt-5 mt-7"
            >
              <IconAddressBook
                className={`w-5 h-5 text-sidebarText ${(pathname ===
                  "/organization" ||
                  pathname === "/organization/add-product") &&
                  "text-white"} group-hover:text-white transition-all duration-300 ease-in-out`}
              />
              <div>
                <p
                  className={`text-base font-ibm-regular text-sidebarText ${(pathname ===
                    "/organization" ||
                    pathname === "/organization/add-product") &&
                    "text-white"} group-hover:text-white leading-none transition-all duration-300 ease-in-out`}
                >
                  Org. Directory
                </p>
              </div>
            </Link>

            {/* MEMBERS */}
            <Link
              to={"/members"}
              className="w-full group flex items-center gap-3 first-of-type:mt-5 mt-7"
            >
              <IconUsers
                className={`w-5 h-5 text-sidebarText ${(pathname ===
                  "/members" ||
                  pathname === "/members/add-product") &&
                  "text-white"} group-hover:text-white transition-all duration-300 ease-in-out`}
              />
              <div>
                <p
                  className={`text-base font-ibm-regular text-sidebarText ${(pathname ===
                    "/members" ||
                    pathname === "/members/add-product") &&
                    "text-white"} group-hover:text-white leading-none transition-all duration-300 ease-in-out`}
                >
                  Members
                </p>
              </div>
            </Link>
          </div>

          {/* MANAGE QUERIES */}
          <div className="mt-10">
            <h5 className="text-xs font-ibm-semibold text-white text-opacity-50 uppercase">
              Manage Queries
            </h5>

            <Link
              to={"/contact-us"}
              className="w-full group flex items-center gap-3 first-of-type:mt-5 mt-7"
            >
              <IconHeadphones
                className={`w-5 h-5 text-sidebarText ${pathname ===
                  "/contact-us" &&
                  "text-white"} group-hover:text-white transition-all duration-300 ease-in-out`}
              />
              <div>
                <p
                  className={`text-base font-ibm-regular text-sidebarText ${pathname ===
                    "/contact-us" &&
                    "text-white"} group-hover:text-white leading-none transition-all duration-300 ease-in-out`}
                >
                  Contact us
                </p>
              </div>
            </Link>

            <Link
              to={"/newsletter"}
              className="w-full group flex items-center gap-3 first-of-type:mt-5 mt-7"
            >
              <IconMail
                className={`w-5 h-5 text-sidebarText ${pathname ===
                  "/newsletter" &&
                  "text-white"} group-hover:text-white transition-all duration-300 ease-in-out`}
              />
              <div>
                <p
                  className={`text-base font-ibm-regular text-sidebarText ${pathname ===
                    "/newsletter" &&
                    "text-white"} group-hover:text-white leading-none transition-all duration-300 ease-in-out`}
                >
                  Newsletter
                </p>
              </div>
            </Link>

            <Link
              to={"/advertisement"}
              className="w-full group flex items-center gap-3 first-of-type:mt-5 mt-7"
            >
              <IconAd
                className={`w-5 h-5 text-sidebarText ${pathname ===
                  "/advertisement" &&
                  "text-white"} group-hover:text-white transition-all duration-300 ease-in-out`}
              />
              <div>
                <p
                  className={`text-base font-ibm-regular text-sidebarText ${pathname ===
                    "/advertisement" &&
                    "text-white"} group-hover:text-white leading-none transition-all duration-300 ease-in-out`}
                >
                  Advertisements
                </p>
              </div>
            </Link>

            <Link
              to={"/medical-aid"}
              className="w-full group flex items-center gap-3 first-of-type:mt-5 mt-7"
            >
              <IconFileSymlink
                className={`w-5 h-5 text-sidebarText ${pathname ===
                  "/medical-aid" &&
                  "text-white"} group-hover:text-white transition-all duration-300 ease-in-out`}
              />
              <div>
                <p
                  className={`text-base font-ibm-regular text-sidebarText ${pathname ===
                    "/medical-aid" &&
                    "text-white"} group-hover:text-white leading-none transition-all duration-300 ease-in-out`}
                >
                  Medical Aid
                </p>
              </div>
            </Link>
          </div>

          {/* CONTENT MANAGEMENT */}
          <div className="mt-10 hidden">
            <h5 className="text-xs font-proxima-semibold text-white text-opacity-50">
              Content Management
            </h5>

            <Link
              to={"/cms/view/homepage"}
              className="w-full group flex items-center gap-3 first-of-type:mt-5 mt-7"
            >
              <TbBrandGoogleHome
                className={`w-5 h-5 text-sidebarText ${pathname ===
                  "/cms/view/homepage" &&
                  "text-white"} group-hover:text-white transition-all duration-300 ease-in-out`}
              />
              <div>
                <p
                  className={`text-base font-proxima-semibold text-sidebarText ${pathname ===
                    "/cms/view/homepage" &&
                    "text-white"} group-hover:text-white leading-none transition-all duration-300 ease-in-out`}
                >
                  Homepage
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sidebar;
